@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;

* {
  outline: none;
  font-family: "Poppins", sans-serif;
  margin: 0;
}
.react-date-picker__wrapper {
  border: none !important;
  /* border-bottom: 1px solid grey !important; */
}

input::placeholder {
  font-size: 1.2em;
}
input[type="checkbox"]:before {
  background-color: green;
}

.my-menu::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
.react-date-picker__clear-button {
  /* display: none; */
  padding: 0;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}
.react-date-picker__inputGroup {
  /* font-size: 1.1rem; */
}
.main {
  /* background: rgba(0, 0, 0, 0.5); */
  /* backdrop-filter: blur(2.3px); */
}
.ball {
  margin-top: 50px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: cornflowerblue;
  border: 2px solid #999;
  animation: bounce 1s infinite alternate;
  -webkit-animation: bounce 1s infinite alternate;
}
/* .bannerbg {
  background-image: url("Components/bannerbg.png");
} */

@keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-15px);
  }
}
@-webkit-keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-15px);
  }
}

@tailwind utilities;
